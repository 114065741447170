import { Flex} from '@chakra-ui/react';
import PropTypes from 'prop-types';

function AuthIllustration(props: { children: JSX.Element | string; illustrationBackground: string }) {
	const { children, illustrationBackground } = props;
	return (
		<Flex position='relative' h='max-content'>
			<Flex
				h={{
					sm: 'initial',
					md: 'initial',
					lg: '100vh',
					xl: '97vh'
				}}
				w={{ sm: '100%', md: '600px', lg: '600px' }}
				mx='auto'
				pt={{ sm: '50px', md: '40px' }}
				px={{ lg: '30px', xl: '0px' }}
				ps={{ xl: '70px' }}
				justifyContent='center'
				alignItems='center'
				>
				{children}
			</Flex>
		</Flex>
	);
}

AuthIllustration.propTypes = {
	illustrationBackground: PropTypes.string,
	image: PropTypes.any
};

export default AuthIllustration;
