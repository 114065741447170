import React from "react";
import { NavLink } from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";

import Card from 'components/card/Card';
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";

function ResetPassword() {
    const [password, setPassword] = React.useState("");
    const [Repassword, setRePassword] = React.useState("");
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const handleReset = async (event: any) => {
        event.preventDefault();

        const serverApi = process.env.REACT_APP_API;
        if (password == Repassword) {
            const response = await axios.post(`${serverApi}` + `resetpassword`, {
                "code": code,
                "password": password
            });
            if (response.data.status == false) {
                alert(response.data.result)
            } else {
                window.location.href = '/';
            }
        }
    };
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [show, setShow] = React.useState(false);
    const handleShow = () => setShow(!show);

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Card py='15px' alignItems='center' flexDirection='column' w='100%'>
                <Flex
                    maxW={{ base: "100%", md: "max-content" }}
                    w='100%'
                    mx={{ base: "auto", lg: "0px" }}
                    me='auto'
                    h='100%'
                    align='center'
                    justifyContent='space-between'
                    mb={{ base: "30px", md: "60px" }}
                    px={{ base: "25px", md: "0px" }}
                    mt={{ base: "20px", md: "7vh" }}
                    flexDirection='column'>
                    <Box me='auto' w='100%'>
                        <Heading color={textColor} fontSize='30px' mb='30px' textAlign='center' w='100%'>
                            Reset the password.
                        </Heading>
                    </Box>
                    <Flex

                        zIndex='2'
                        direction='column'
                        w={{ base: "100%", md: "420px" }}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{ base: "auto", lg: "unset" }}
                        me='auto'
                        mb={{ base: "20px", md: "auto" }}>
                        <FormControl my='10px'>
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'>
                                Password<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size='md'>
                                <Input
                                    isRequired={true}
                                    fontSize='sm'
                                    placeholder='Enter your password'
                                    mb='24px'
                                    size='lg'
                                    type={show ? "text" : "password"}
                                    variant='auth'
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                <InputRightElement display='flex' alignItems='center' mt='4px'>
                                    <Icon
                                        color={textColorSecondary}
                                        _hover={{ cursor: "pointer" }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleShow}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'>
                                Retype Password<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size='md'>
                                <Input
                                    isRequired={true}
                                    fontSize='sm'
                                    placeholder='Enter re-type password'
                                    mb='24px'
                                    size='lg'
                                    type={show ? "text" : "password"}
                                    variant='auth'
                                    onChange={(event) => setRePassword(event.target.value)}
                                />
                                <InputRightElement display='flex' alignItems='center' mt='4px'>
                                    <Icon
                                        color={textColorSecondary}
                                        _hover={{ cursor: "pointer" }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleShow}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <Button
                                fontSize='sm'
                                variant='brand'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                onClick={handleReset}
                            >
                                Reset the password
                            </Button>
                        </FormControl>
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='0px'>
                            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                                Don’t have any account?
                                <NavLink to='/auth/sign-up'>
                                    <Text
                                        color={textColorBrand}
                                        as='span'
                                        ms='5px'
                                        fontWeight='500'>
                                        Sign Up
                                    </Text>
                                </NavLink>
                                <NavLink to='/auth/sign-in'>
                                    <Text
                                        color={textColorBrand}
                                        as='span'
                                        ms='5px'
                                        fontWeight='500'>
                                        Sign In
                                    </Text>
                                </NavLink>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Card>
        </DefaultAuth>
    );
}

export default ResetPassword;
