const fetchTradingData = async (apiUrl: string, params: Record<string, any> = {}): Promise<any | null> => {
  try {
    const url = new URL(apiUrl);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url.toString());
    const data: any = await response.json();
    return data.result;
  } catch (error) {
    console.error('Failed to fetch data:', error);
    return null;
  }
};

export default fetchTradingData;