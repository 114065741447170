import { Flex, Box, Table, Stack, Tbody, Td, Text, Skeleton, Tr, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';

// const columns = columnsDataCheck;
export default function FundsUsdt(props: { tableData: any }) {
	const { tableData } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Funds Distribution USDT
				</Text>
			</Flex>
			<Box>
				{
					tableData == null ? <Stack width='100%' px='20px' mt='30px'>
						<Skeleton height='35px' />
						<Skeleton height='35px' />
						<Skeleton height='35px' />
						<Skeleton height='35px' />
					</Stack> : <Table variant='simple' color='gray.500' mb='24px' mt="12px">
						<Tbody>
							<Tr
								borderBottom='1px'
								borderBottomColor='grey.200'
								borderBottomStyle='dotted'
							>
								<Td
									padding='3'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									${tableData && Number(isNaN(tableData.total_balance) ? 0: tableData.total_balance ).toFixed(2)}
								</Td>
								<Td
									padding='3'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									100.00%
								</Td>
								<Td
									padding='3'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									Total Balance
								</Td>
							</Tr>
							<Tr
								borderBottom='1px'
								borderBottomColor='grey.200'
								borderBottomStyle='dotted'
							>
								<Td
									color='blackAlpha.900'
									padding='3'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									${tableData && Number(isNaN(tableData.balance_free) ? 0: tableData.balance_free ).toFixed(2)}
								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									{Number(isNaN(tableData.percent_free) ? 0 : tableData.percent_free ).toFixed(2)}%
								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									Available USDT

								</Td>
							</Tr>
							<Tr
								borderBottom='1px'
								borderBottomColor='grey.200'
								borderBottomStyle='dotted'
							>
								<Td
									color='blackAlpha.900'
									padding='3'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									${tableData && Number(isNaN(tableData.balance_long) ? 0: tableData.balance_long ).toFixed(2)}
								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									{Number(isNaN(tableData.percent_long) ? 0 : tableData.percent_long ).toFixed(2)}%
								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									Long
								</Td>
							</Tr>
							<Tr
								borderBottom='1px'
								borderBottomColor='grey.200'
								borderBottomStyle='dotted'
							>
								<Td
									color='blackAlpha.900'
									padding='3'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									${tableData && Number(isNaN(tableData.balance_short) ? 0: tableData.balance_short ).toFixed(2)}

								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									{Number(isNaN(tableData.percent_short) ? 0 : tableData.percent_short ).toFixed(2)}%
								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									Short

								</Td>
							</Tr>
							<Tr
								borderBottom='1px'
								borderBottomColor='grey.200'
								borderBottomStyle='dotted'
							>
								<Td
									padding='3'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									${tableData && Number(isNaN(tableData.balance_used) ? 0: tableData.balance_used ).toFixed(2)}
								</Td>
								<Td
									padding='3'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									{Number(isNaN(tableData.percent_used) ? 0 : tableData.percent_used ).toFixed(2)}%
								</Td>
								<Td
									padding='3'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									Total Positions
								</Td>
							</Tr>
						</Tbody>
					</Table>
				}

			</Box>
		</Card>
	);
} 