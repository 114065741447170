import { Flex, Box, Stack, Table, Tbody, Td, Text, Tr, useColorModeValue, Skeleton } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function BestTradingPair(props: { tableData: any }) {
	const { tableData } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Best Trading Pairs
				</Text>
			</Flex>
			<Box>
				<Table variant='simple' color='black' mb='24px' mt="12px">
					{
						tableData == null ? <Stack width='100%' px='20px' mt='30px'>
							<Skeleton height='35px' />
							<Skeleton height='35px' />
							<Skeleton height='35px' />
							<Skeleton height='35px' />
							<Skeleton height='35px' />
						</Stack> : ""
					}


					<Tbody>
						{
							tableData && tableData.map((data: any, index: any) => {
								return < Tr
									borderBottom='1px'
									borderBottomColor='grey.200'
									borderBottomStyle='dotted'
									key={index}
								>
									<Td
										padding='3'
										fontSize={{ sm: '18px' }}
										fontWeight='300'
										color='black'
										minW={{ sm: '150px', md: '200px', lg: 'auto' }}
									>
										{data.symbol}
									</Td>

									<Td
										padding='3'
										fontSize={{ sm: '18px' }}
										fontWeight='300'
										color='black'
										minW={{ sm: '150px', md: '200px', lg: 'auto' }}
									>
										${Number(data.profit).toFixed(2)}
									</Td>
								</Tr>
							})
						}
					</Tbody>
				</Table>
			</Box>
		</Card >
	);
} 