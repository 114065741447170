import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import SingUp from 'views/auth/signUp';
import ForgotPassword from 'views/auth/forgot/forgot_password';
import ResetPassword from 'views/auth/reset/reset_password';
import VerifyEmail from 'views/auth/verify/verify_email';
ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<Router>
				<Switch>
					<Route path={`/sign-in`} component={AuthLayout} />
					<Route path={`/sign-up`} component={SingUp} />
					<Route path={`/forgotpassword`} component={ForgotPassword} />
					<Route path={`/resetpassword`} component={ResetPassword} />
					<Route path={`/verify`} component={VerifyEmail} />
					<Route path={`/`} component={AdminLayout} />
					<Redirect from='*' to='/' />
				</Switch>
			</Router>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
