import { Flex, Box, Table,Tbody, Td, Text, Th, Thead, Tr, Input, Select, Button, useToken } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormControl } from '@chakra-ui/react';
import { CircularProgress } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import axios from 'axios';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card';


// const columns = columnsDataCheck;
export default function ColumnTable() {
	const [initialUsers, setInitialUsers] = useState([]);
	const [initialTVIDS, setInitialTVIDS] = useState([]);
	const [initialExchange, setInitialExchange] = useState([]);
	const [maindata, setMainData] = useState([])
	const [total_logs, setTotalLogs] = useState();
	const [current_page, setCurrnetPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const serverApi = process.env.REACT_APP_API;
	const [oddRowColor] = useToken('colors', ['gray.200']);

	const [userId, setUserID] = useState('');
	const [filterTvId, setFilterTvId] = useState("");
	const [filterDate, setFilterDate] = useState("");
	const [exchangeValue, setExchangeValue] = useState("");
	const [state, setState ] = useState({
		selectedOption: 10,
		options: [
			{ value: 10, label: "10" },
			{ value: 20, label: "20" },
			{ value: 30, label: "30" }
		]
	});
	useEffect(() => {
		setLoading(true);
		// Define an async function to fetch data
		const fetchData = async () => {
			try {
				const response = await axios.get(`${serverApi}getlogsfilteroption`);
				setInitialUsers(response.data.result.users);
				setInitialTVIDS(response.data.result.tv_ids);
				setInitialExchange(response.data.result.exchanges);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		const mainData = async () => {
			try {
				const response = await axios.get(`${serverApi}logs/?page=1&limit=${state.selectedOption}&user_id=&tv_id=&exchange=&date=&created_at=`);
				setMainData(response.data.result);
				setTotalLogs(response.data.result[0].total_logs)
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}
		mainData();
		fetchData();
	}, []);
	const nextPage = async () => {
		setLoading(true);

		const tableResponse = await axios.get(`${serverApi}logs/?page=${current_page + 1}&limit=${state.selectedOption}&user_id=&tv_id=&exchange=&date=&created_at=`);
		setMainData(tableResponse.data.result);
		setTotalLogs(tableResponse.data.result[0].total_logs);
		setCurrnetPage(current_page + 1);

		setLoading(false);

	}
	const beforePage = async () => {
		setLoading(true);

		const tableResponse = await axios.get(`${serverApi}logs/?page=${current_page + 1}&limit=${state.selectedOption}&user_id=&tv_id=&exchange=&date=&created_at=`);
		setMainData(tableResponse.data.result);
		setTotalLogs(tableResponse.data.result[0].total_logs);
		if (current_page > 1) {
			setCurrnetPage(current_page - 1);
		}
		setLoading(false);

	}
	const handleEmail = async (value: any) => {
		setLoading(true);

		if (value === '') {
			try {
				const response = await axios.get(`${serverApi}logs/?page=${current_page}&limit=${state.selectedOption}&user_id=&tv_id=${filterTvId}&exchange=${exchangeValue}&date=${filterDate}&created_at=${filterDate}`);
				setMainData(response.data.result);
				setTotalLogs(response.data.result[0].total_logs);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}
		try {
			const response = await axios.get(`${serverApi}logs/?page=${current_page}&limit=${state.selectedOption}&user_id=${value}&tv_id=${filterTvId}&exchange=${exchangeValue}&date=${filterDate}&created_at=${filterDate}`);
			setMainData(response.data.result);
			setTotalLogs(response.data.result[0].total_logs);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setUserID(value);
		setLoading(false);

	}

	const handleTvId = async (value: any) => {
		setLoading(true);

		try {
			const response = await axios.get(`${serverApi}logs/?page=${current_page}&limit=${state.selectedOption}&user_id=${userId}&tv_id=${value}&exchange=${exchangeValue}&date=${filterDate}&created_at=${filterDate}`);
			setMainData(response.data.result);
			setTotalLogs(response.data.result[0].total_logs);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setFilterTvId(value);
		setLoading(false);

	}

	const handleExchanges = async (value: any) => {
		setLoading(true);

		try {
			const response = await axios.get(`${serverApi}logs/?page=${current_page}&limit=${state.selectedOption}&user_id=${userId}&tv_id=${filterTvId}&exchange=${value}&date=${filterDate}&created_at=${filterDate}`);
			setMainData(response.data.result);
			setTotalLogs(response.data.result[0].total_logs);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setExchangeValue(value);
		setLoading(false);

	}

	const handleDate = async (value: any) => {
		setLoading(true);

		try {
			const response = await axios.get(`${serverApi}logs/?page=${current_page}&limit=${state.selectedOption}&user_id=${userId}&tv_id=${filterTvId}&exchange=${exchangeValue}&date=${value}&created_at=${value}`);
			setMainData(response.data.result);
			setTotalLogs(response.data.result[0].total_logs);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setFilterDate(value);
		setLoading(false);

	}
	const DateTimeConverter = (originalDateTimeString: string) => {
		if (originalDateTimeString === null || originalDateTimeString === '') {
				return;
		}
		const dateTime = new Date(originalDateTimeString);

		const formattedTime = dateTime.toLocaleTimeString([], { hour12: false });
		const formattedDate = dateTime.toLocaleDateString();
		return `${formattedTime} ${formattedDate}`;

	}
	const handleChange = async (event: any) => {
		await setState({ ...state, selectedOption: event.target.value });
		setLoading(true);
		const tableResponse = await axios.get(`${serverApi}logs/?page=${current_page + 1}&limit=${event.target.value}&user_id=&tv_id=&exchange=&date=&created_at=`);
		setMainData(tableResponse.data.result);
		setTotalLogs(tableResponse.data.result[0].total_logs);
		setCurrnetPage(current_page);
		setLoading(false);

	};
	return (
		<Box>
			<Box>
				<form>
					<Flex flexDirection='row' gap='3' w={{ sm: '100%', lg: '80%' }}>
						<FormControl>
							<Select placeholder='All' onChange={(e) => handleEmail(e.target.value)}>
								{initialUsers.map((data: any, index: any) => (
									<option key={index} value={data.id}>{data.name} ({data.email})</option>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<Select placeholder='All TV IDS' onChange={(e) => handleTvId(e.target.value)}>
								{initialTVIDS.map((data: any, index: any) => (
									<option key={index} value={data.tv_id}>{data.tv_id}</option>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<Select placeholder='All Exchanges' onChange={(e) => handleExchanges(e.target.value)}>
								{initialExchange.map((data: any, index: any) => (
									<option key={index} value={data.name}>{data.name}</option>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<Input
								type="date" onChange={(e) => handleDate(e.target.value)}
							/>
						</FormControl>
					</Flex>
				</form>
			</Box>
			<Card flexDirection='column' w='100%' mt='10px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
				<Box>
					<Text fontSize='20px' mb='20px'>Logs</Text>
					<HSeparator mb='20px' />
					{
						loading == false ? <><Table variant='simple' color='gray.900' mb='24px' mt="12px">
							<Thead>
								<Tr height="60px">
									<Th fontSize='16px' textAlign='left' fontWeight='500'>
										User
									</Th>
									<Th fontSize='16px' textAlign='left' fontWeight='500'>
										TV ID
									</Th>
									<Th fontSize='16px' textAlign='left' fontWeight='500'>
										Exchage
									</Th>
									<Th fontSize='16px' textAlign='left' fontWeight='500'>
										Symbol
									</Th>
									<Th fontSize='16px' textAlign='left' fontWeight='500'>
										Log
									</Th>
									<Th fontSize='16px' textAlign='left' fontWeight='500'>
										Created At
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{
									maindata && maindata.map((data: any, index: any) => {
										return (
											<Tr key={index} bg={index % 2 === 0 ? oddRowColor : ''}>
												<Td>
													{data.user_id} - {data.name}
												</Td>
												<Td>
													{data.tv_id}
												</Td>
												<Td>
													{data.exchange}
												</Td>
												<Td>
													{data.symbol}
												</Td>
												<Td>
													{data.log}
												</Td>
												<Td>
													{DateTimeConverter(data.created_at)}
												</Td>
											</Tr>
										)
									})
								}
							</Tbody>
						</Table>
							<Flex justifyContent='space-between'>
								<Flex justifyContent='space-between' alignItems='center'>
									<Text whiteSpace="nowrap">{Number(state.selectedOption) * (current_page - 1) + 1} - {Number(state.selectedOption) * current_page} of {total_logs} Items per page</Text>
										<Select value={state.selectedOption} onChange={handleChange} marginLeft='3'>
												{state.options.map((option: any) => (
														<option key={option.value} value={option.value}>
																{option.label}
														</option>
												))}
										</Select>
								</Flex>
								<Flex justifyContent='center' textAlign='center' alignItems='center'>
									{
										current_page === 1 ? <Button cursor='not-allowed'><ArrowBackIcon /></Button> : <Button onClick={(e) => beforePage()}><ArrowBackIcon /></Button>
									}

									<Text px='10px'>{current_page}</Text>
									{
										current_page < Math.floor(Number(total_logs) / 10) + 1 ? <Button onClick={(e) => nextPage()}><ArrowForwardIcon /></Button> : <Button cursor='not-allowed'><ArrowForwardIcon /></Button>
									}
								</Flex>
							</Flex></> : <CircularProgress isIndeterminate color='lightblue' />
					}

				</Box>
			</Card>
		</Box>
	);
} 