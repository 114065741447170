// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
// Custom components
import ReactECharts from 'echarts-for-react';

export default function WeeklyRevenue(props: { tableData: any }) {
	let { tableData } = props;
	if (tableData == null) {
		tableData = { "status": false, "series": [], "xaxis": [] }
	}
	// ECharts options
	const option = {
		xAxis: {
			type: 'category',
			data: tableData && tableData.xaxis
		},
		yAxis: {
			type: 'value',
			minInterval: 30
		},
		series: [
			{
				type: 'bar',
				name: 'USDT',
				data: tableData && tableData.series,

			}
		],
    tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			},
	}
	};
	

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<Card flexDirection='column' w='100%' px='15px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text me='auto' color={textColor} fontSize='xl' mt='15px' fontWeight='700' lineHeight='100%'>
					Weekly Revenue
				</Text>
			</Flex>
			<ReactECharts
				option={option}
				style={{ width: '100%', height: "400px" }}
			/>
		</Card>
	);
}
